/* eslint-disable @typescript-eslint/no-magic-numbers */
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Content } from "pdfmake/interfaces";

import { environment } from "../../../../../environments/environment";
import { Measurement } from "../../../datamodel/measurement";
import { MeasurementTable } from "../drawables/measurement-table";
import { Table } from "../drawables/table/table";
import { PdfGenerator } from "../pdf-generator";
import { PdfTemplateHelper } from "../pdf-template-helper";
import { PdfTemplates } from "../pdf-templates";

/**
 * QuickModePDF generates a PDF listing the given measurements.
 */
@Injectable({
    providedIn: "root"
})
export class QuickModePdf {

    constructor(
        private translationService: TranslateService,
        private pdfGenerator: PdfGenerator,
        private http: HttpClient
    ) {
    }

    public async generatePDF(measurements: Array<Measurement>, onDocumentReady: (base64Data: string) => void): Promise<void> {
        const tables: Array<Table> = new MeasurementTable(this.translationService).buildByMeasurements(measurements);
        const renderedTables: Array<Content> = [];
        for (const table of tables) {
            renderedTables.push(table.render());
        }

        const template: PdfTemplates = PdfTemplateHelper.getTemplateByMeasurements(measurements);

        this.pdfGenerator.generate(template, DateTime.local().toFormat("yyyy-MM-dd"), true, {
            content: [
                {
                    image: environment.rhopointLogo,
                    width: 250,
                    alignment: "center"
                },
                {
                    text: "\n\n\n\n"
                },
                {
                    text: `${this.translationService.instant("Pdf.titleQuickModeMeasurements")}`,
                    style: "h1"
                },
                ...renderedTables
            ]
        }, onDocumentReady);
    }
}
