export enum DeviceNames {
    unknown = "unknown",
    iq = "IQ +++",
    iqFamily = "IQ, Novo-Gloss, Novo-Shade",
    detailometer = "Detailometer",
    duo = "Novo-Shade Duo",
    novoGloss = "Novo-Gloss",
    flex = "IQ Flex",

    thicknessGauge = "Coating Thickness Gauge"
}
