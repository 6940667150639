import { DeviceNames } from "../../../base/services/ble/devices/device-names";
import { Job } from "../../datamodel/job";
import { Measurement } from "../../datamodel/measurement";
import { PdfColors } from "./pdf-colors";
import { PdfTemplates } from "./pdf-templates";

/**
 * Helper class for retrieving the appropriate PDF template based on job details.
 */
export class PdfTemplateHelper {
    public static readonly colorLinesRhopoint: string = "#0c469c";
    public static readonly colorLinesDetailometer: string = "#c39a51";

    public static readonly colorPropertyTableValueBackgroundRhopoint: string = "#e3ecfa";
    public static readonly colorPropertyTableKeyBackgroundRhopoint: string = "#cedcf1";
    public static readonly colorPropertyTableValueBackgroundDetailometer: string = "#f9f3e9";
    public static readonly colorPropertyTableKeyBackgroundDetailometer: string = "#ecdcc0";

    public static getTemplateByMeasurements(measurements: Array<Measurement>): PdfTemplates {
        const usedDevices: Set<string> = new Set<string>();
        for (const measurement of measurements) {
            if (measurement.device) {
                usedDevices.add(measurement.device);
            }
        }

        const isDetailometer: boolean = (usedDevices.size == 1 && usedDevices.has(DeviceNames.detailometer))
                || (usedDevices.size == 1 && usedDevices.has(DeviceNames.thicknessGauge))
                || (usedDevices.size == 2 && usedDevices.has(DeviceNames.detailometer) && usedDevices.has(DeviceNames.thicknessGauge));

        return isDetailometer ? PdfTemplates.detailometer : PdfTemplates.rhopointInstruments;
    }

    public static getTemplateByJob(job: Job): PdfTemplates {
        for (const measuredPoint of job.measuredPoints) {
            const template: PdfTemplates = this.getTemplateByMeasurements(measuredPoint.measurements);
            if (template != PdfTemplates.rhopointInstruments) {
                return template;
            }
        }
        return PdfTemplates.rhopointInstruments;
    }

    public static getColor(color: PdfColors, template: PdfTemplates): string {
        switch (color) {
            case PdfColors.propertyTableKeyBackground: return template == PdfTemplates.detailometer ? this.colorPropertyTableKeyBackgroundDetailometer : this.colorPropertyTableKeyBackgroundRhopoint;
            case PdfColors.propertyTableValueBackground: return template == PdfTemplates.detailometer ? this.colorPropertyTableValueBackgroundDetailometer : this.colorPropertyTableValueBackgroundRhopoint;
            case PdfColors.lines: return template == PdfTemplates.detailometer ? this.colorLinesDetailometer : this.colorLinesRhopoint;
            default: return "#000000";
        }
    }
}
