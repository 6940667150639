import { MatDialog } from "@angular/material/dialog";
import { BleDevice } from "@capacitor-community/bluetooth-le";

import { Measurement } from "../../../../business/datamodel/measurement";
import { SettingsService } from "../../../../business/services/settings/settings-service";
import { BluetoothStatus } from "../bluetooth-status";
import { DeviceNames } from "./device-names";

/**
 * Baseclass for all Bluetooth devices which are supported in this application.
 */
export abstract class BluetoothDevice {
    protected constructor(name: string) {
        this.deviceName = name;
    }

    public deviceName: string = DeviceNames.unknown;

    public abstract requiredServices: Array<string>;
    public abstract requiredAdvertisingServices: Array<string>;
    public abstract requiredNamePrefix?: string;

    public abstract connect(device: BleDevice, dialog: MatDialog, settingsService: SettingsService, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus>;

    public abstract disconnect(): Promise<void>;

    public abstract isConnected(): Promise<boolean>;
}
