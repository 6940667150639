/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Content } from "pdfmake/interfaces";

import { environment } from "../../../../../environments/environment";
import { readFileAsDataUrlAsync } from "../../../../base/helper/file-reader-helper";
import { Job } from "../../../datamodel/job";
import { MeasuredValue } from "../../../datamodel/measured-value";
import { Part } from "../../../datamodel/part";
import { Property } from "../../../datamodel/property";
import { StatisticsCalculator } from "../../../helpers/statistics/statistics-calculator";
import { ImageService } from "../../image/image-service";
import { Personalization } from "../../settings/personalization";
import { SettingsService } from "../../settings/settings-service";
import { Line } from "../drawables/line";
import { MeasurementTable } from "../drawables/measurement-table";
import { StatisticsTable } from "../drawables/statistics-table";
import { Row } from "../drawables/table/row";
import { Table } from "../drawables/table/table";
import { PdfColors } from "../pdf-colors";
import { PdfGenerator } from "../pdf-generator";
import { PdfTemplateHelper } from "../pdf-template-helper";
import { PdfTemplates } from "../pdf-templates";

/**
 * JobModePdf allows to export the data of a job into a pdf.
 */
@Injectable({
    providedIn: "root"
})
export class JobModePdf {

    constructor(
        private translationService: TranslateService,
        private pdfGenerator: PdfGenerator,
        private imageService: ImageService,
        private settingsService: SettingsService
    ) {
    }

    public async generatePDF(job: Job, onDocumentReady: (base64Data: string) => void): Promise<void> {
        const template: PdfTemplates = PdfTemplateHelper.getTemplateByJob(job);
        const templateSuffix: string = template == PdfTemplates.rhopointInstruments ? "" : PdfTemplates.detailometer[0].toUpperCase() + PdfTemplates.rhopointInstruments.substring(1);
        this.pdfGenerator.generate(template, this.formatJobDate(job, false), false, {
            content: [
                ...await this.printCustomerData(),
                {
                    text: "\n\n\n\n"
                },
                {
                    text: `${this.translationService.instant(`JobReport.reportTitle${templateSuffix}`)}`,
                    style: "h1"
                },
                new Line(4, PdfTemplateHelper.getColor(PdfColors.lines, template)).render(),
                {
                    text: "\n\n\n\n"
                },
                ...await this.printLogosOnTitlePage(template),
                {
                    text: `${this.translationService.instant("JobReport.generalInfo")}`,
                    style: ["text12", "bold"]
                },
                {
                    text: "\n\n"
                },
                {
                    text: `${this.translationService.instant("JobReport.reportName")}`,
                    style: ["text10", "bold"]
                },
                {
                    text: Job.getJobDisplayName(job) ? `${this.translationService.instant(Job.getJobDisplayName(job)!)}` : `${this.translationService.instant("Job.unnamed")}`,
                    style: "text10"
                },
                {
                    text: "\n\n"
                },
                {
                    text: `${this.translationService.instant("JobReport.reportDate")}`,
                    style: ["text10", "bold"]
                },
                {
                    text: this.formatJobDate(job, true),
                    style: "text10"
                },
                {
                    text: "\n\n"
                },
                {
                    text: `${this.translationService.instant("JobReport.serialNumber")}`,
                    style: ["text10", "bold"]
                },
                {
                    text: this.collectSerialNumbers(job).join("\n"),
                    style: "text10"
                },
                {
                    text: "\n\n"
                },
                this.printVehicleDataPage(job, template, templateSuffix),
                this.printPartAverages(job, template),
                await this.printParts(job, template)
            ]
        }, onDocumentReady);
    }

    private formatJobDate(job: Job, time: boolean): string {
        let ret: string = "---";
        if (job.dateISO) {
            ret = DateTime.fromISO(job.dateISO).toFormat(time ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd");
        }
        if (ret == "[object Object]") {
            ret = JSON.stringify(job.dateISO);
        }
        return ret;
    }

    private collectSerialNumbers(job: Job): Array<string> {
        const serials: Array<string> = [];

        for (const mp of job.measuredPoints) {
            for (const m of mp.measurements) {
                if (m.device) {
                    const serial: string = `${m.serialNumber == undefined ? "" : m.serialNumber} ${m.device}`.trim();
                    if (!serials.includes(serial)) {
                        serials.push(serial);
                    }
                }
            }
        }
        return serials;
    }

    private async printLogosOnTitlePage(template: PdfTemplates): Promise<Array<Content>> {
        const content: Array<Content> = [];
        if (template == PdfTemplates.detailometer) {
            content.push({
                image: environment.rhopointInstrumentsLogo,
                width: 120,
                absolutePosition: {
                    x: 40,
                    y: 790
                }
            });
            content.push({
                image: environment.detailometerLogo,
                width: 100,
                alignment: "right",
                absolutePosition: {
                    x: 0,
                    y: 770
                }
            });
        } else {
            content.push({
                image: environment.rhopointInstrumentsLogo,
                width: 120,
                absolutePosition: {
                    x: 40,
                    y: 790
                }
            });
        }
        return content;
    }

    public async printCustomerData(): Promise<Array<Content>> {
        const content: Array<Content> = [];

        const customerData: Personalization = await this.settingsService.loadPersonalization();

        if (customerData.logoAsDataUrl && customerData.logoAsDataUrl.length > 0) {
            content.push({
                image: customerData.logoAsDataUrl,
                width: 150,
                alignment: "left"
            });

        }
        let marginTopNeeded: boolean = false;
        if (customerData.companyName && customerData.companyName.length > 0) {
            content.push({
                text: customerData.companyName,
                style: ["text10", "bold"]
            });
            marginTopNeeded = true;
        }
        if (customerData.companyAddress && customerData.companyAddress.length > 0) {
            content.push({
                text: customerData.companyAddress,
                style: "text10"
            });
            marginTopNeeded = true;
        }

        if (customerData.website && customerData.website.length > 0) {
            content.push({
                text: customerData.website,
                style: "text10",
                margin: marginTopNeeded ? [0, 5, 0, 0] : undefined
            });
        }
        if (customerData.email && customerData.email.length > 0) {
            content.push({
                text: customerData.email,
                style: "text10"
            });
        }
        if (customerData.phone && customerData.phone.length > 0) {
            content.push({
                text: customerData.phone,
                style: "text10"
            });
        }
        return content;
    }

    private printPartAverages(job: Job, template: PdfTemplates): Array<Content> {

        const content: Array<Content> = [];
        const table: Table = new Table();

        const columnNames: Array<string> = [];
        for (const mp of job.measuredPoints) {
            for (const measurement of mp.measurements) {
                for (const value of measurement.values) {
                    if (value.name && !columnNames.includes(value.name) && MeasuredValue.isValidValue(value)) {
                        columnNames.push(value.name);
                    }
                }
            }
        }

        if (columnNames.length == 0) {
            return content;
        }

        table.columns.push({
            width: "*",
            title: `${this.translationService.instant("Property.name")}`,
            fillColor: PdfGenerator.colorBlack,
            color: PdfGenerator.colorWhite
        });

        for (const column of columnNames) {
            table.columns.push({
                width: "auto",
                title: `${this.translationService.instant(column)}`,
                fillColor: PdfGenerator.colorBlack,
                color: PdfGenerator.colorWhite
            });
        }

        for (const part of job.parts) {
            const statistics: StatisticsCalculator = new StatisticsCalculator();
            for (const measuredPoint of job.measuredPoints) {
                if (part.id == measuredPoint.partId) {
                    for (const measurement of measuredPoint.measurements) {
                        for (const measuredValue of measurement.values) {
                            if (measuredValue.name && measuredValue.value && MeasuredValue.isValidValue(measuredValue)) {
                                statistics.add(measuredValue.name, measuredValue.value);
                            }
                        }
                    }
                }
            }
            const row: Row = new Row();
            row.cells.push({
                text: Part.getDisplayName(part),
                style: ["bold"],
                fillColor: PdfGenerator.colorLightGrey
            });
            for (const name of columnNames) {
                const avg: number|undefined = statistics.avg(name);
                row.cells.push({
                    text: StatisticsTable.formatNumber(avg),
                    alignment: "right"
                });
            }
            table.rows.push(row);

        }

        content.push({
            text: `${this.translationService.instant("JobReport.averageValues")}`,
            style: "h2",
            pageBreak: "before"
        });
        content.push(new Line(1, PdfTemplateHelper.getColor(PdfColors.lines, template)).render());
        content.push({
            text: "\n\n\n"
        });
        content.push({
            text: `${this.translationService.instant("JobReport.jobReport")}: ${Job.getJobDisplayName(job)}`,
            style: "h3"
        });
        content.push(table.render());
        return content;
    }

    private async printParts(job: Job, template: PdfTemplates): Promise<Array<Content>> {
        const partPages: Array<Content> = [];
        for (const part of job.parts) {
            partPages.push(...await this.printPart(job, part, template));
        }
        return partPages;
    }

    private async printPart(job: Job, part: Part, template: PdfTemplates): Promise<Array<Content>> {
        const content: Array<Content> = [];
        content.push({
            text: `${this.translationService.instant("JobReport.report")}`,
            style: "h2",
            pageBreak: "before"
        });
        content.push(new Line(1, PdfTemplateHelper.getColor(PdfColors.lines, template)).render());
        content.push({
            text: "\n\n\n"
        });
        content.push({
            text: this.translationService.instant("PdfExport.partName") + Part.getDisplayName(part),
            style: "h3"
        });

        content.push(this.buildPropertiesTable(part.properties, true, template).render());
        content.push(await this.printPartPhotos(part, job));
        content.push(this.printPartMeasurements(part, job));
        return content;
    }

    private async printPartPhotos(part: Part, job: Job): Promise<Array<Content>> {
        const content: Array<Content> = [];

        // template image
        const useImageWithMeasurementPoints: boolean = this.countMeasurements(job) > 0;
        if (part.image) {
            if (useImageWithMeasurementPoints && part.image.imageWithMeasurementPointsAsDataURL) {
                content.push({
                    image: part.image.imageWithMeasurementPointsAsDataURL,
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    width: 250
                });
            } else {
                if (part.image.binaryId) {
                    const binary: Blob|undefined = await this.imageService.load(part.image.binaryId);
                    if (binary) {
                        const file: File = new File([binary], binary.type);
                        const dataUrl: string = await readFileAsDataUrlAsync(file);
                        content.push({
                            image: dataUrl,
                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                            width: 250
                        });
                    }
                }
            }
        }

        // additional images uploaded to part
        for (const photo of job.photos) {
            if (photo.partId == part.id && photo.binaryId) {
                const binary: Blob|undefined = await this.imageService.load(photo.binaryId);
                if (binary) {
                    const file: File = new File([binary], binary.type);
                    const dataUrl: string = await readFileAsDataUrlAsync(file);
                    content.push({
                        image: dataUrl,
                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                        width: 250
                    });
                }
            }
        }
        if (content.length == 0) {
            return [];
        }

        const table: Table = new Table();
        table.columns.push({
            width: "auto"
        });
        table.columns.push({
            width: "auto"
        });
        table.renderHeaderRow = false;
        table.renderBorders = false;
        for (let i: number = 0; i < content.length; i++) {
            const left: Content = content[i];
            let right: Content|undefined = undefined;
            if (i + i < content.length) {
                right = content[i + 1];
                i++;
            }
            table.rows.push({
                cells: [left, right ? right : { text: "" }]
            });
        }
        return [table.render()];
    }

    private buildPropertiesTable(properties: Array<Property>, colored: boolean, template: PdfTemplates): Table {
        const table: Table = new Table();
        table.renderHeaderRow = false;
        table.columns.push({
            width: "*"
        });
        table.columns.push({
            width: "*"
        });
        let fillColorLeft: string = PdfGenerator.colorWhite;
        let fillColorRight: string = PdfGenerator.colorWhite;
        if (colored) {
            fillColorLeft = PdfTemplateHelper.getColor(PdfColors.propertyTableKeyBackground, template);
            fillColorRight = PdfTemplateHelper.getColor(PdfColors.propertyTableValueBackground, template);
        }
        for (const property of properties) {
            const propertyName: string = property.nameKey ? `${this.translationService.instant(property.nameKey)}` : `${this.translationService.instant("Property.noKey")}`;
            const propertyValue: string = property.value ? property.value : "---";
            table.rows.push({
                cells: [{
                    text: propertyName,
                    fillColor: fillColorLeft
                }, {
                    text: propertyValue,
                    fillColor: fillColorRight
                }]
            });
        }

        return table;
    }

    private printVehicleDataPage(job: Job, template: PdfTemplates, templateSuffix: string): Array<Content> {
        const table: Table = this.buildPropertiesTable(job.properties, false, template);
        table.rows.push({
            cells: [
                {
                    text: `${this.translationService.instant("JobTemplate.numberOfParts")}`,
                    fillColor: PdfGenerator.colorWhite
                },
                {
                    text: `${job.parts.length}`,
                    fillColor: PdfGenerator.colorWhite
                }]
        });
        table.renderBorders = false;

        return [
            {
                text: `${this.translationService.instant(`JobReport.summaryTitle${templateSuffix}`)}`,
                style: "h2",
                pageBreak: "before"
            },
            new Line(1, PdfTemplateHelper.getColor(PdfColors.lines, template)).render(),
            {
                text: "\n\n\n"
            },
            {
                text: `${this.translationService.instant("JobReport.jobReport")}: ${Job.getJobDisplayName(job)}`,
                style: "h3"
            },
            table.render()
        ];
    }

    private countMeasurementPoints(job: Job): number {
        let count: number = 0;
        for (const part of job.parts) {
            if (part.image) {
                count += part.image.measurementPoints.length;
            }
        }
        return count;
    }

    private countMeasurements(job: Job): number {
        let count: number = 0;
        for (const measurementPoint of job.measuredPoints) {
            count += measurementPoint.measurements.length;
        }
        return count;
    }

    private printPartMeasurements(part: Part, job: Job): Array<Content> {
        const contents: Array<Content> = [];
        const tables: Array<Table> = new MeasurementTable(this.translationService).buildByJobAndPart(job, part);
        for (const table of tables) {
            contents.push(table.render());
        }
        return contents;
    }

}
